import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-services.service';

@Component({
  selector: 'app-target-achievement-filter',
  templateUrl: './target-achievement-filter.component.html',
  styleUrls: ['./target-achievement-filter.component.scss']
})
export class TargetAchievementFilterComponent implements OnInit {
  @Output() reportClick: EventEmitter<Record<string, any>> = new EventEmitter();
  @Output() close: EventEmitter<Record<string, any>> = new EventEmitter();

  months: string[] = [];
  years: number[] = [];


  filterForm = new FormGroup({
    month: new FormControl(null, [Validators.required]),
    year: new FormControl(null, [Validators.required])
  });

  constructor(public apiService: ApiService) {

  }

  ngOnInit(): void {
    this.getMonths()
    this.getYears()
  }



  private getMonths() {
    this.apiService.getMonths().subscribe(
      (result: any) => {
        this.months = result;
      },
      (error) => {
        this.months = [];
      }
    );
  }

  private getYears() {
    this.apiService.getYears().subscribe(
      (result: any) => {
        this.years = result;
      },
      (error) => {
        this.years = [];
      }
    );
  }

  viewReport() {
    if (this.filterForm.invalid) {
      return;
    }
    this.reportClick.emit(this.filterForm.value);
    this.close.emit()
  }
}
