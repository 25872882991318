import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) {
  }

  map = (data) => this.http.get(`http://maps.google.co.in/maps?q=${data}`)

  //login api
  login = (userData) => this.http.post(`${environment.loginApiUrl}`, userData)
  sendOtpForLogin = (mobileNumber) => this.http.post(`${environment.loginApiUrl}otp/send?mobile=${mobileNumber}`, {})
  loginThroughOtp = (userData) => this.http.post(`${environment.loginApiUrl}validateOtp`, userData)
  userAccess = (mobileNumber) => this.http.get(`${environment.loginApiUrl}user-access?username=${mobileNumber}&appName=MarketingAdmin`)

  //school api's
  openSchoolList = (pageNo) => this.http.get(`${environment.apiUrl}schools/unassigned?page=${pageNo}`)
  assignedSchoolList = (schoolData) => this.http.get(`${environment.apiUrl}schools/assigned?page=${schoolData.page}&size=${schoolData.size}`)
  assignedSchoolSummery = (schoolId) => this.http.get(`${environment.apiUrl}task-planner/by-school?schoolId=${schoolId}`)
  addSchool = (schoolData) => this.http.post(`${environment.apiUrl}schools`, schoolData)
  getSchoolProfileById = (schoolId) => this.http.get(`${environment.apiUrl}schools?schoolId=${schoolId}`)
  filterSchool = (searchString, filterData, page, size = 15) => this.http.get(`${environment.apiUrl}schools/smart-search?searchString=${searchString}&assignedTo=${filterData.assignedTo}&city=${filterData.city}&district=${filterData.district}&state=${filterData.state}&leadType=${filterData.leadType}&conversionChances=${filterData.conversionChances}&isProposalSubmitted=${filterData.isProposalSubmitted}&schoolName=${filterData.schoolName}&page=${page}&size=${size}`)
  deleteSchool = (schoolId) => this.http.delete(`${environment.apiUrl}schools?schoolId=${schoolId}`)
  updateSchoolDetails = (schoolData) => this.http.put(`${environment.apiUrl}schools`, schoolData)
  searchSchool = (schoolData) => this.http.get(`${environment.apiUrl}schools/search?searchString=${schoolData}`)
  updateTask = (taskData) => this.http.put(`${environment.apiUrl}task-planner`, taskData)
  getSchoolContacts = (schoolId) => this.http.get(`${environment.apiUrl}schools/contact?schoolId=${schoolId}`)
  addSchoolContact = (contactData) => this.http.post(`${environment.apiUrl}schools/contact?schoolId=${contactData.schoolId}`, contactData)
  updateSchoolContact = (contactData) => this.http.put(`${environment.apiUrl}schools/contact?schoolId=${contactData.schoolId}`, contactData)
  // searchUnAssignedSchool = (schoolData) => this.http.get(`${environment.apiUrl}schools/search?searchString=vidya=${schoolData}`)
  getGoogleMapKey = () => this.http.get(`${environment.apiUrl}schools/gmap-properties`)
  getSchoolSuggestions = (char, key) => this.http.get<[any, string[]]>(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${char}&key=${key}`)

  //employees api's
  getSupervisors = () => this.http.get(`${environment.apiUrl}employees/role?role=Supervisor`)
  getAllEmployees = () => this.http.get(`${environment.apiUrl}employees`)
  getEmployeeById = (employeeId) => this.http.get(`${environment.apiUrl}employees?employeeProfileId=${employeeId}`)
  assignOpenSchool = (employeeId, schoolId) => this.http.put(`${environment.apiUrl}schools/assign?assignedTo=${employeeId}&schoolId=${schoolId}`, {})
  addEmployee = (empData) => this.http.post(`${environment.apiUrl}employees`, empData)
  employeeVisitDetails = (employeeId) => this.http.get(`${environment.apiUrl}tasks/totalVisits?employeeProfileId=${employeeId}`)
  employeeLeadDetails = (employeeId) => this.http.get(`${environment.apiUrl}schools/reports/leadType?employeeProfileId=${employeeId}`)
  updateEmployeeDetails = (empData) => this.http.put(`${environment.apiUrl}employees`, empData)
  deleteEmployee = (employeeId) => this.http.delete(`${environment.apiUrl}employees?employeeProfileId=${employeeId}`)
  employeeLeadTrackWeekly = (weekNo, employeeId, page) => this.http.get(`${environment.apiUrl}tasks?week=${weekNo}&employeeProfileId=${employeeId}&page=${page}`)
  /**
   * Method to get employee lead track weekly
   * @param date object of date which is in format of yyyy-mm-dd
   * @param profileId is the employee profile id
   * @param pagination is the pagination object
   * @returns data of tasks
   */
  getleads = (data) => {
    const slug = `${environment.apiUrl}task-planner?employeeProfileId=${data.profileId}&startDate=${data['date'].startDate}&endDate=${data['date'].endDate}&page=${data['pagination'].page}&size=${data['pagination'].size}`;
    return this.http.get(slug);
  };

  //image upload
  getImageUrl = (moduleName, fileName) => this.http.get(`${environment.apiUrl}documents/get?module=${moduleName}&fileName=${fileName}`)
  uploadImage = (moduleName, fileName) => this.http.put(`${environment.apiUrl}documents/upload?module=${moduleName}&fileName=${fileName}&contentType=image/png`, {})
  uploadImageByUrl = (url, image) => this.http.put(`${url}`, image);

  //dashboard
  leadDataChart = () => this.http.get(`${environment.apiUrl}schools/reports/leadType`).toPromise()
  getTotalVisits = () => this.http.get(`${environment.apiUrl}tasks/totalVisits`)
  best10Marketer = () => this.http.get(`${environment.apiUrl}schools/reports/leadType/employee-wise`)
  getProgressYearly = () => this.http.get(`${environment.apiUrl}tasks/progress`)
  getProgressWeekly = (weekNo) => this.http.get(`${environment.apiUrl}tasks/progress?week=${weekNo}`)
  getProgressMonthly = (monthNo) => this.http.get(`${environment.apiUrl}tasks/progress?month=${monthNo}`)

  //attendance
  getAttendanceList = (attendanceData) => this.http.get(`${environment.apiUrl}attendance?fromDate=${attendanceData.fromDate}&toDate=${attendanceData.toDate}&employeeProfileId=${attendanceData.employeeProfileId}`);
  getDateWiseAttendanceList = (attendanceDate) => this.http.get(`${environment.apiUrl}attendance/date?date=${attendanceDate}`);
  getEmployeeList = () => this.http.get(`${environment.apiUrl}employees`)

  //dayPlanSummary
  getDayPlanSummaryList(params, employeeIds) {
    if (params.orderBy && params.direction) {
      return this.http.post(`${environment.apiUrl}day-plan/summary?fromDate=${params.fromDate}&toDate=${params.toDate}&page=${params.page}&size=${params.size}&orderBy=${params.orderBy}&direction=${params.direction}`, employeeIds);
    } else {
      return this.http.post(`${environment.apiUrl}day-plan/summary?fromDate=${params.fromDate}&toDate=${params.toDate}&page=${params.page}&size=${params.size}`, employeeIds);
    }
  }

  //task planner
  getTaskPlannerList(params, employeeIds) {
    if (params.orderBy && params.direction) {
      return this.http.post(`${environment.apiUrl}task-planner/by-employees?startDate=${params.fromDate}&endDate=${params.toDate}&page=${params.page}&size=${params.size}&orderBy=${params.orderBy}&direction=${params.direction}`, employeeIds)
    } else {
      return this.http.post(`${environment.apiUrl}task-planner/by-employees?startDate=${params.fromDate}&endDate=${params.toDate}&page=${params.page}&size=${params.size}`, employeeIds)
    }
  }

  //application setting
  getApplicationSetting = () => this.http.get(`${environment.apiUrl}application-settings`)
  updateApplicationSetting = (data) => this.http.put(`${environment.apiUrl}application-settings`, data)
  getMonths = () => this.http.get(`${environment.apiUrl}task-planner/getMonthsInAYear`)
  getYears = () => this.http.get(`${environment.apiUrl}task-planner/getYearList`)
  getTargetVsAchievementsReport = (month: string, year: number) => this.http.get(`${environment.apiUrl}task-planner/getTargetVsAchievementsReport?month=${month}&year=${year}`)
}
