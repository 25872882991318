import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MainInterceptor } from './services/main.interceptor';
import { AgmCoreModule, GoogleMapsAPIWrapper  } from '@agm/core';
import { ErrorComponent } from './components/error/error.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SidebarModule } from 'ng-sidebar';
import { ReportSidebarComponent } from './shared/report-sidebar/report-sidebar.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ReportSidebarService } from './services/report-sidebar.service';
import { TargetAchievementFilterComponent } from './shared/report-sidebar/target-achievement-filter/target-achievement-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OutsideClickDirective } from './directives/outside-click.directive';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    ReportSidebarComponent,
    TargetAchievementFilterComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      easing: 'ease-in',
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhgLrIAKVNSa1S2QZYKsaevSiTO6xrtWU',
      libraries: ['places']
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    NgbDatepickerModule,
    AccordionModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    SidebarModule.forRoot()
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: MainInterceptor,
    multi: true
  },GoogleMapsAPIWrapper, ReportSidebarService ],
  bootstrap: [AppComponent]
})
export class AppModule {}
