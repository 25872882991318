import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  loginData: any;

  constructor(private route: Router, private spinner: NgxSpinnerService, private toast: ToastrService) {

   }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.spinner.show()

    let authReq
    if (request.url) {
      if ((request.url.includes('otp/send')) || (request.url.includes('validateOtp'))) {
        localStorage.clear();
        authReq = request.clone({});
      } else {
        authReq = request.clone({});
      }
      if (request.url.includes('marketing-app.s3.ap-south-1')) {
        authReq = request.clone({
          headers: new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":"OPTIONS, GET, PUT, PATCH, POST, DELETE"
          })
        })
      } else if(request.url.includes('maps.googleapis.com')) {
        authReq = request.clone({
          headers: new HttpHeaders({
            "Accept" : "*/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
          })
        })
      } else if (localStorage.getItem('token')) {
        authReq = request.clone({
          setHeaders: {
            Authorization: localStorage.getItem('token'),
            COMPANY_CODE: localStorage.getItem('companyCode'),
            APP_NAME: 'MarketingAdmin'
          }
        })
      }
      return next.handle(authReq).pipe(tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinner.hide()
          }
        }, error => {
          if (error?.error || error) {
            this.spinner.hide()
            this.toast.error(error?.error?.message || error?.message)
            // localStorage.clear();
            // this.route.navigate(['/home']);
          }
          // else if (error?.error?.status === 406 || error?.status === 406) {
          //   this.spinner.hide()
          //   this.toast.error(error?.error?.message)
          //   localStorage.clear();
          //   this.route.navigate(['/home']);
          // }
          // else if (error?.error?.status === 500 || error?.status === 500) {
          //   this.spinner.hide()
          //   this.route.navigate(['/error'], {queryParams: {errorMsg : "Internal server error"}});
          // }
          else{
            this.spinner.hide()
          }
        }
      ));
    } else {
      this.spinner.hide()
      return next.handle(request);
    }
  }
}
