<div class="main-container">
  <div class="container">
    <div class="error-page">
      <img src="../../../assets/images/error.png">
      <h1>Sorry</h1>
      <h3 *ngIf="errorMsg == undefined; else ElseBlock"> Page not found</h3>
      <ng-template #ElseBlock>
        <h3> {{errorMsg}}</h3>
      </ng-template>
      <a href="javascript:void" [routerLink]="['/home']">Go Back</a>
    </div>
  </div>
</div>
