<form class="row form-body " novalidate [formGroup]="filterForm" (ngSubmit)="viewReport()">
    <div class="col-12 form-group my-2">
        <ng-select [items]="months" formControlName="month" placeholder="Select Month"
        [multiple]="false">
   </ng-select>
    </div>
    
    <div class="col-12 form-group my-2">
        <ng-select [items]="years" formControlName="year"
                    placeholder="Select Year" ></ng-select>
    </div>
    <div class="col-12 my-2 ">
        <button class="app-btn float-right" [disabled]="filterForm.invalid" type="submit"><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Submit</button>
    </div>
</form>

