<ng-sidebar-container>

    <ng-sidebar #sidebarReport mode="over" [showBackdrop]="true" [animate]="true" position="right" [autoFocus]="true"
        sidebarClass="app-box sidebarClass" [(opened)]="openReportsNavBar">
        <report-sidebar *ngIf="openReportsNavBar" (closeReport)="sidebarReport.close()"></report-sidebar>
    </ng-sidebar>
    <!-- Page content -->
    <div ng-sidebar-content>
        <ngx-spinner [fullScreen]="true" type="square-jelly-box" size="medium">
            <p style="color: white;">Loading ...</p>
        </ngx-spinner>
        <router-outlet></router-outlet>
    </div>
</ng-sidebar-container>