import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-sidebar',
  templateUrl: './report-sidebar.component.html',
  styleUrls: ['./report-sidebar.component.scss']
})
export class ReportSidebarComponent implements OnInit {

  @Output()
  closeReport: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {

  }



  reportClick(reportType: string, data: Record<string, any>) {
    let route: string[] = ['/report', 'target-achievement-report'];
    data.reportType = reportType;
    this.router.navigate(route, { queryParams: data });
    this.closeReport.emit();
  }
}
