import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, Event, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';
import { ReportSidebarService } from './services/report-sidebar.service';
import { Subscription } from 'rxjs';
import { CommonUtility } from './utils/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{

  openReportsNavBar:boolean=false;
  private reportSidebarSubscription: Subscription;

  constructor(private spinner: NgxSpinnerService, private route: Router, private reportSidebarService: ReportSidebarService){
    this.route.events.subscribe((event: Event)=>{
      switch(true){
        case event instanceof NavigationStart: {
          this.spinner.show();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:{
          this.spinner.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.reportSidebarSubscription = this.reportSidebarService.isReportSidebarVisible$.subscribe(data => {
      this.openReportsNavBar = data;
    });
    this.setDatatable();
  }
  
  openReports(sidebarReport: any){
    this.openReportsNavBar = !this.openReportsNavBar;
    // if(this.openReportsNavBar) this.openReport.emit();
    // else this.closeReport.emit();
    sidebarReport.open();
  }

  setDatatable() {
    CommonUtility.datatable = {
      "VISIT_TARGET_ACHIEVEMENT_REPORT": [{
        columnName: "weekNumber",
        columnWidth: null,
        displayName: "Week Number",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "weekNumberInYear",
        columnWidth: null,
        displayName: "Week Number In Year",
        displayOrder: 1,
        id: 68022,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "employeeName",
        columnWidth: null,
        displayName: "Employee Name",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "targetVisits",
        columnWidth: null,
        displayName: "Target Visits",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "achievedVisits",
        columnWidth: null,
        displayName: "Achieved Visits",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }], "DEMO_TARGET_ACHIEVEMENT_REPORT": [{
        columnName: "weekNumber",
        columnWidth: null,
        displayName: "Week Number",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "weekNumberInYear",
        columnWidth: null,
        displayName: "Week Number In Year",
        displayOrder: 1,
        id: 68022,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "employeeName",
        columnWidth: null,
        displayName: "Employee Name",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "targetDemos",
        columnWidth: null,
        displayName: "Target Demos",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "achievedDemos",
        columnWidth: null,
        displayName: "Achieved Demos",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }], "SIGNUP_TARGET_ACHIEVEMENT_REPORT": [{
        columnName: "weekNumber",
        columnWidth: null,
        displayName: "Week Number",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "weekNumberInYear",
        columnWidth: null,
        displayName: "Week Number In Year",
        displayOrder: 1,
        id: 68022,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "employeeName",
        columnWidth: null,
        displayName: "Employee Name",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "targetSignUps",
        columnWidth: null,
        displayName: "Target SignUps",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "achievedSignUps",
        columnWidth: null,
        displayName: "Achieved SignUps",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "targetVisits",
        columnWidth: null,
        displayName: "Target Visits",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "achievedVisits",
        columnWidth: null,
        displayName: "Achieved Visits",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "targetDemos",
        columnWidth: null,
        displayName: "Target Demos",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }, {
        columnName: "achievedDemos",
        columnWidth: null,
        displayName: "Achieved Demos",
        displayOrder: 1,
        id: 68021,
        isDefault: "Y",
        module: "MARKETING",
        profileType: "ALL",
        tableName: "TARGET_ACHIEVEMENT_REPORT"
      }]
    }; 
  }

  ngOnDestroy(): void {
    this.reportSidebarSubscription.unsubscribe();
  }

}
