import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportSidebarService {
  private isReportSidebarVisibleSubject = new BehaviorSubject<boolean>(false);
  public isReportSidebarVisible$ = this.isReportSidebarVisibleSubject.asObservable();

  showSidebar() {
    this.isReportSidebarVisibleSubject.next(true);
  }

  hideSidebar() {
    this.isReportSidebarVisibleSubject.next(false);
  }
}