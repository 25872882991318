
<div class="title-row">
    <div class="title">
        <i class="fa fa-times mr-3" (click)="closeReport.emit()"></i>
        Reports
    </div>
</div>
<div class="row m-0">
    <div class="col-12 px-0">
        <accordion [isAnimated]="true" [closeOthers]="true">
            <accordion-group>
                <button class="btn btn-link btn-block report-header  clearfix" accordion-heading type="button">
                    <div class="pull-left float-left">Visit Target Vs Achievement report</div>
                    <i class="fa fa-angle-down pull-right float-right" aria-hidden="true"></i>
                </button>
                <app-target-achievement-filter (reportClick)="reportClick('VISIT', $event)"></app-target-achievement-filter>
            </accordion-group>
            <accordion-group>
                <button class="btn btn-link btn-block report-header  clearfix" accordion-heading type="button">
                    <div class="pull-left float-left">Demo's Target Vs Achievement report</div>
                    <i class="fa fa-angle-down pull-right float-right" aria-hidden="true"></i>
                </button>
                <app-target-achievement-filter (reportClick)="reportClick('DEMO', $event)"></app-target-achievement-filter>
            </accordion-group>
            <accordion-group>
                <button class="btn btn-link btn-block report-header  clearfix" accordion-heading type="button">
                    <div class="pull-left float-left">Signup's Target Vs Achievement report</div>
                    <i class="fa fa-angle-down pull-right float-right" aria-hidden="true"></i>
                </button>
                <app-target-achievement-filter (reportClick)="reportClick('SIGNUP', $event)"></app-target-achievement-filter>
            </accordion-group>
        </accordion>
    </div>
</div>
